import classNames from 'classnames';
import React, { ReactNode, memo, ButtonHTMLAttributes, useCallback, MouseEvent } from 'react';
import cls from './Button.module.css';
import { IconLoaderAlt } from '@new/components/icon';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	children: ReactNode;
	outline?: boolean;
	loading?: boolean;
	icon?: React.ReactElement;
}

export const Button = memo((props: IButtonProps) => {
	const { className, children, outline, disabled, onClick, loading, icon, ...otherProps } = props;
	const iconOutput = () => {
		return icon && !loading ? (
			<span className={classNames(cls.IconParent)}>{icon}</span>
		) : typeof loading === 'boolean' ? (
			<span className={classNames(cls.IconParent, cls.Loading, { [cls.LoadingActive]: loading })}>
				<IconLoaderAlt className={classNames(cls.Icon)} />
			</span>
		) : null;
	};

	const handlerCLick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			if (!disabled) onClick?.(e);
		},
		[onClick, disabled]
	);
	return (
		<button
			className={classNames(className, cls.Button, outline && cls.outline, disabled && cls.disabled)}
			onClick={handlerCLick}
			{...otherProps}
		>
			{iconOutput()}
			{children}
		</button>
	);
});
