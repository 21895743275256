import React, { FC, InputHTMLAttributes, memo } from 'react';
import cls from './Input.module.css';
import classNames from 'classnames';

type IInputProps = InputHTMLAttributes<HTMLInputElement>;

export const Input = memo((props: IInputProps) => {
	const { className, ...otherProps } = props;

	return <input className={classNames(cls.Input, className)} {...otherProps} />;
});
