import React, { memo, useLayoutEffect, useState } from 'react';
import { default as ImageAntd, ImageProps } from 'next/image';
import cls from './AppImage.module.css';
import classNames from 'classnames';

interface IAppImage extends Omit<ImageProps, 'src'> {
	src: string;
}

export const AppImage = memo((props: IAppImage) => {
	const { src = '', width, height, fill, ...otherProps } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);

	useLayoutEffect(() => {
		const img = new Image();
		img.src = src || '';
		img.onload = () => {
			setIsLoading(false);
		};
		img.onerror = () => {
			setIsLoading(false);
			setHasError(true);
		};
	}, [src]);

	if (isLoading)
		return <div className={classNames(cls.Skeleton, fill && cls.full)} style={{ width: width, height: height }}></div>;

	return <ImageAntd src={src} {...otherProps} width={width} height={height} fill={fill} />;
});
