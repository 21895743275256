import classNames from 'classnames';
import React, { useState, HTMLAttributes, memo, useRef } from 'react';
import { IconPlayBtn } from '../../icon';
import { Flex } from '../Flex';
import cls from './Video.module.css';
import Image from 'next/image';

interface IVideoProps extends HTMLAttributes<HTMLDivElement> {
	className?: string;
	src: string;
	poster?: string;
	playBtnSize?: 'big' | 'small';
	hasImage?: boolean;
	onChangeVideo?(): void;
	title: string;
}

export const Video = memo(
	({ src, poster, className, playBtnSize = 'big', hasImage, onChangeVideo, title, ...otherProps }: IVideoProps) => {
		const [isPlaying, setIsPlaying] = useState(false);

		const isVkOrYouTube = src.includes('vk.com') || src.includes('youtube');
		const video = useRef<HTMLVideoElement>(null);

		const handlePlay = () => {
			void video.current?.play();
			setIsPlaying(true);
		};

		const handlePause = () => {
			void video.current?.pause();
			setIsPlaying(false);
		};

		if (hasImage) {
			return (
				<div {...otherProps} className={classNames(className, cls.Video)} onClick={onChangeVideo}>
					<>
						{!hasImage && isPlaying && <div className={cls.over} onClick={() => handlePause()} />}
						{!hasImage && (
							<Flex justifyContent='flex-center' alignItems='center' className={cls.cover}>
								<Flex
									justifyContent='flex-center'
									alignItems='center'
									className={classNames(cls.play, playBtnSize === 'big' && cls.big)}
									onClick={() => handlePlay()}
								>
									<IconPlayBtn />
								</Flex>
							</Flex>
						)}
						{!hasImage && (
							<>
								<video src={src} ref={video} poster={poster} />{' '}
							</>
						)}
						{hasImage && <Image src={poster || ''} layout='fill' alt={title} title={title} />}
					</>
				</div>
			);
		}

		return (
			<div {...otherProps} className={classNames(className, cls.Video)}>
				{isVkOrYouTube && <iframe src={src} title='Iframe player' frameBorder={'0px'} scrolling='no'></iframe>}
				{!isVkOrYouTube && (
					<>
						{isPlaying ? (
							<div className={cls.over} onClick={() => handlePause()} />
						) : (
							<Flex justifyContent='flex-center' alignItems='center' className={cls.cover}>
								<Flex
									justifyContent='flex-center'
									alignItems='center'
									className={classNames(cls.play, playBtnSize === 'big' && cls.big)}
									onClick={() => handlePlay()}
								>
									<IconPlayBtn />
								</Flex>
							</Flex>
						)}
						<video src={src} ref={video} poster={poster} />{' '}
					</>
				)}
			</div>
		);
	}
);
