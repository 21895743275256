import React, { memo, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cls from './Portal.module.scss';

interface IPortalProps {
	children: ReactNode;
	elementId?: string;
}

export const Portal = (props: IPortalProps) => {
	const { children, elementId = '__next' } = props;
	const [element, setElement] = useState<HTMLElement | null>(null);

	useEffect(() => {
		setElement(document.getElementById(elementId));
	}, [elementId]);
	if (element === null) return null;

	return createPortal(children, element);
};
