import cls from './DotsLoading.module.css';

export const DotsLoading = () => {
	return (
		<svg height='38' width='38' className={cls.loader}>
			<circle className={cls.dot} cx='10' cy='20' r='3' />
			<circle className={cls.dot} cx='20' cy='20' r='3' />
			<circle className={cls.dot} cx='30' cy='20' r='3' />
		</svg>
	);
};

DotsLoading.displayName = 'DotsLoading';
