export { default as IconArrow } from './arrow.svg';
export { default as IconPin } from './pin.svg';
export { default as IconCar } from './car.svg';
export { default as IconFilter } from './filter.svg';
export { default as IconSearch } from './search.svg';
export { default as IconChat } from './chat.svg';
export { default as IconShare } from './share.svg';
export { default as IconPointer } from './pointer.svg';
export { default as IconSearchAbout } from './searchAbout.svg';
export { default as IconUserCheck } from './userCheck.svg';
export { default as IconCalendar } from './calendar.svg';
export { default as IconCalendarBlue } from './calendar-blue.svg';
export { default as IconCheckBox } from './checkBox.svg';
export { default as IconPerson } from './person.svg';
export { default as IconPlanet } from './globe.svg';
export { default as IconProviders } from './providers.svg';
export { default as IconImage } from './image.svg';
export { default as IconStar } from './star.svg';
export { default as IconStarBlue } from './star-blue.svg';
export { default as IconStarFullBlue } from './starBlue.svg';
export { default as IconArrowCircle } from './arrow-circle.svg';
export { default as IconArrowSubmit } from './arrow-submit.svg';
export { default as IconPlay } from './play.svg';
export { default as IconPlayCircle } from './play-circle.svg';
export { default as IconCloseRed } from './close-red.svg';
export { default as IconCross } from './cross.svg';
export { default as IconArrowDownBlue } from './arrow-down-blue.svg';
export { default as IconArrowDown } from './arrow-down.svg';
export { default as IconArrowDownBlueTwo } from './arrow-down-blue-two.svg';
export { default as IconCheck } from './check.svg';
export { default as IconClose } from './close.svg';
export { default as IconCloseWhite } from './close-white.svg';
export { default as IconArrowDouble } from './dubl-arrow.svg';
export { default as PercentCircle } from './percent-in-circle.svg';
export { default as IconMinusGrey } from './minus-grey.svg';
export { default as IconPlus } from './plus.svg';
export { default as IconLoop } from './loop.svg';
export { default as IconVk } from './vk1.svg';
export { default as IconVkSocial } from './vk3.svg';
export { default as IconMailC } from './mailCircle.svg';
export { default as IconTelegram } from './tg1.svg';
export { default as IconInternet } from './internet.svg';
export { default as IconMedal } from './medal.svg';
export { default as IconPhone } from './phone.svg';
export { default as IconMail } from './mail.svg';
export { default as IconKingBad } from './KingBed.svg';
export { default as IconCLock } from './Clock.svg';
export { default as IconArrowSLider } from './arrow-slider.svg';
export { default as IconWarningTwo } from './warningTwo.svg';
export { default as IconWallet } from './wallet.svg';
export { default as IconLunchBurger } from './lunch_burger.svg';

export { default as IconActivity } from './activity.svg';
export { default as IconActivityBlue } from './filters/activity.svg';
export { default as IconBed } from './filters/bed.svg';
export { default as IconHumbugger } from './filters/humbugger.svg';
export { default as IconPlace } from './filters/place.svg';
export { default as IconCity } from './filters/city.svg';

export { default as IconTour } from './tour.svg';
export { default as IconSpb } from './SPB.svg';
export { default as IconLogoWhite } from './logo-white.svg';
export { default as IconAppPage } from './android-app.svg';
export { default as IconClip } from './clip.svg';
export * from './DotsLoading';
export { default as IconCorporateFare } from './corporateFare.svg';
