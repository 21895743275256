import React, { useRef, memo } from 'react';
import classNames from 'classnames';
import { SwiperSlide } from 'swiper/react';
import { Fancybox } from '@fancyapps/ui';
import { Swiper } from 'swiper/react';

import { Pagination, Navigation } from 'swiper';
import { AppImage } from '../AppImage';
import { IconArrowRightSlide } from '../../icon';

import '@fancyapps/ui/dist/fancybox/fancybox.css';

import cls from './Slider.module.css';
import { useRouter } from 'next/router';

interface ISliderProps {
	className?: string;
	images?: string[];
	navigationShow?: boolean;
	paginationClassName?: string;
	paginationLarge?: boolean;
	hasGallery?: boolean;
}

// TODO
// Добавить условие при которых стрелки будут скрыты
export const Slider = memo(
	({ className, images, navigationShow = true, paginationClassName, paginationLarge, hasGallery }: ISliderProps) => {
		const next = useRef(null);
		const prev = useRef(null);
		const hasNavigation = navigationShow && images && images?.length > 1;

		const showFancy = (startIndex: number) => {
			if (!hasGallery) return;
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			new Fancybox(
				images?.map(src => ({
					src,
				})) || [],
				{
					startIndex,
				}
			);
		};

		return (
			<Swiper
				modules={[Pagination, Navigation]}
				pagination={{
					horizontalClass: paginationClassName || cls.pagination,
					bulletClass: classNames({ [cls.bulletXL]: paginationLarge }, cls.bullet),
					bulletActiveClass: cls.active,

					clickable: true,
					enabled: !!hasNavigation,
				}}
				loop={true}
				className={classNames(className, cls.Slider, 'newUiSLider')}
				slidesPerView={1}
				navigation={{
					nextEl: next.current,
					prevEl: prev.current,
				}}
				onBeforeInit={(swiper: any) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					swiper.params.navigation.prevEl = prev.current;
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					swiper.params.navigation.nextEl = next.current;
				}}
			>
				{images &&
					images.map((i, index) => (
						<SwiperSlide key={i} className={cls.slide}>
							<AppImage src={i} alt={`image ${index}`} fill onClick={() => showFancy(index)} />
						</SwiperSlide>
					))}

				{hasNavigation && (
					<div ref={next} className={classNames(cls.arrow, cls.right)}>
						<IconArrowRightSlide />
					</div>
				)}
				{hasNavigation && (
					<div ref={prev} className={classNames(cls.arrow)}>
						<IconArrowRightSlide />
					</div>
				)}
			</Swiper>
		);
	}
);
