import classNames from 'classnames';
import React, { memo, ReactNode, AnchorHTMLAttributes } from 'react';
import cls from './Link.module.css';
import NextLink from 'next/link';
interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	className?: string;
	children: ReactNode;
	outline?: boolean;
	href: string;
}

export const Link = memo((props: ILinkProps) => {
	const { className, href, children, outline, ...otherProps } = props;

	return (
		<NextLink href={href} className={classNames(className, cls.Link, outline && cls.outline)} {...otherProps}>
			{children}
		</NextLink>
	);
});

Link.displayName = 'Link';
