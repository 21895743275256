import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';
import cls from './Flex.module.css';

interface IFlexProps extends HTMLAttributes<HTMLDivElement> {
	className?: string;
	children: ReactNode;
	maxWidth?: boolean;
	justifyContent?: 'between' | 'flex-center' | 'around' | 'flex-start' | 'flex-end';
	alignItems?: 'center' | 'start' | 'end';
	gap?:
		| '40px'
		| '34px'
		| '30px'
		| '28px'
		| '24px'
		| '20px'
		| '18px'
		| '16px'
		| '14px'
		| '12px'
		| '10px'
		| '8px'
		| '6px'
		| '4px'
		| '2px';
	direction?: 'row' | 'column';
	wrap?: boolean;
}

export const Flex = (props: IFlexProps) => {
	const {
		className,
		children,
		justifyContent = 'start',
		alignItems = 'start',
		direction = 'row',
		maxWidth = false,
		wrap,
		gap,
		...otherProps
	} = props;

	return (
		<div
			className={classNames(
				className,
				cls.Flex,

				cls[justifyContent],
				cls[alignItems],
				cls[direction],
				wrap && cls.wrap,
				gap && cls[`gap-${gap}`],
				maxWidth && cls['maxWidth']
			)}
			{...otherProps}
		>
			{children}
		</div>
	);
};
